import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Test And Mock Import With ES6",
  "author": "tzookb",
  "type": "post",
  "date": "2019-11-15T13:50:08.000Z",
  "url": "/test-and-mock-import-with-es6",
  "categories": ["testing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`db.js`}</h4>
    <p>{`some coool stuf with code snippet here`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript{numberLines:",
        "metastring": "true}",
        "true}": true
      }}>{`export default name => {
    return true
}
handleSubmit(event) {
  alert('Your favorite flavor is: ' + this.state.value);
  event.preventDefault();
}
`}</code></pre>
    <h4>{`updateName.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import dbSave from './db';
function updateName(name) {
    const data = dbSave(name)
    return data
}
export default updateName;
`}</code></pre>
    <h4>{`updateName.test.js`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-javascript{numberLines:",
        "metastring": "true}",
        "true}": true
      }}>{`import updateName from './es6-updateName';
jest.mock('./db', () => {
    return {
        __esModule: true,
        default: jest.fn(() => true),
    };
});
describe('update name', () => {
    test('save success got true', () => {
        const res = updateName('dontcare')
        expect(res).toBe(true);
    });
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      